<template>
    <div class="page" v-loading="loading">
        <div style="margin-top: 30px;background-color: #FFFFFF;padding: 30px 0px;">
            <el-tabs tab-position="left" style="height: 700px" v-model="activeName">
                <el-tab-pane name="personalInfo">
                    <span slot="label"><i class="el-icon-user"></i> 个人信息</span>
                    <personalInfo :userInfo="userInfo"></personalInfo>
                </el-tab-pane>
                <el-tab-pane name="changeInfo">
                    <span slot="label"><i class="el-icon-edit"></i> 更新信息</span>
                    <changeInfo :userInfo="userInfo" @successChange="successChange"></changeInfo>
                </el-tab-pane>
                <el-tab-pane name="changePassWord">
                    <span slot="label"><i class="el-icon-unlock"></i> 修改密码</span>
                    <changePassWord :userId="userInfo.id"></changePassWord>
                </el-tab-pane>
                <el-tab-pane name="classDetails">
                    <span slot="label" @click="classVisible=true"><i class="el-icon-notebook-2"></i> 我的课程</span>
                    <classDetails v-if="classVisible"></classDetails>
                </el-tab-pane>
                <el-tab-pane name="personalNews">
                    <span slot="label" @click="personalVisible=true"><i class="el-icon-camera"></i> 个人动态</span>
                    <personalNews v-if="personalVisible"></personalNews>
                </el-tab-pane>
                <el-tab-pane name="pointsDetails">
                    <span slot="label" @click="pointVisible=true"><i class="el-icon-star-off"></i> 积分明细</span>
                    <pointsDetails v-if="pointVisible"></pointsDetails>
                </el-tab-pane>
                <el-tab-pane name="messageCenter">
                    <span slot="label" @click="messageVisible=true"><i class="el-icon-message"></i> 消息中心</span>
                    <messageCenter v-if="messageVisible"></messageCenter>
                </el-tab-pane>
            </el-tabs>
        </div>


    </div>
</template>

<script>
    import personalInfo from "./components/personalInfo.vue"
    import changeInfo from "./components/changeInfo.vue"
    import changePassWord from "./components/changePassWord.vue"
    import personalNews from "./components/personalNews.vue"
    import classDetails from "./components/classDetails.vue"
    import pointsDetails from "./components/pointsDetails.vue"
    import messageCenter from "./components/messageCenter.vue"
    import {selfInfo} from "@/api/index.js"

    export default {
        components: {
            personalInfo,
            changeInfo,
            changePassWord,
            personalNews,
            classDetails,
            pointsDetails,
            messageCenter
        },
        data() {
            return {
                activeName: 'personalInfo', // 默认展示第二个tab
                userInfo: {},
                loading: false,
                classVisible: false,
                personalVisible: false,
                pointVisible: false,
                messageVisible: false,
            }
        },
        mounted() {
            this.activeName = this.$route.params.activeName;
            if (this.activeName ==='' || this.activeName === undefined){
                this.activeName='personalInfo';
            }
            if (this.activeName === 'classDetails') {
                this.classVisible = true;
            }
            this.getInfo()
        },
        methods: {

            getInfo() {
                this.loading = true;
                selfInfo().then(res => {
                    if (res.code === "0000") {
                        this.userInfo = res.data
                        this.loading = false;
                    }
                });
            },
            successChange() {
                this.getInfo()
            }
        }
    }
</script>

<style scoped>
     ::v-deep .el-tabs__nav {
        padding: 0 30px;
    }

     ::v-deep .el-tabs__item {
        margin-top: 10px;
    }

     ::v-deep .el-tabs__item {
        text-align: center !important;
    }

     ::v-deep .el-tabs__item.is-active {
        color: #FFFFFF;
        background-color: #409EFF;
        border-radius: 50px;
    }

     ::v-deep .el-tabs__content {
        height: 600px;
        /*overflow-y: scroll;*/
    }

    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
    }

    .el-aside {
        color: #333;
    }
</style>
